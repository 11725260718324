import React from 'react';
import axios from 'axios';

import styles from './index.module.css';

import SEO from '../components/seo';

const Cards = ({ children }) => (
  <div className={styles.cards}>{children}</div>
);

const Card = ({ children }) => (
  <div className={styles.card}>{children}</div>
);

const IndexPage = () => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    axios
      .get('https://api.purchasing-power-parity.com/?target=ID')
      .then(response => setData(response.data));
  }, []);

  const priceStates = 4.0;
  const priceIndonesia = data
    ? Number(priceStates * data.ppp.pppConversionFactor).toFixed(2)
    : null;

  return (
    <div className={styles.container}>
      <SEO title="Home" />

      <h1>Purchasing Power Parity</h1>

      <Cards>
        <Card>
          <h3>Welcome</h3>

          <p>
            Welcome to the official Purchasing Power Parity API which
            makes your online products and services affordable around
            the world. Reach a broader audience by pricing your online
            goods based on PPP.
          </p>

          <p>
            Note sure why you are here?{' '}
            <a href="https://en.wikipedia.org/wiki/Purchasing_power_parity">
              What is Purchasing Power Parity?
            </a>
          </p>
        </Card>

        <Card>
          <h3>Motivation</h3>

          <p>
            In the US, the average price for a cappuccino is $
            {priceStates.toFixed(2)}. This doesn't mean that everyone
            around the world can afford it. Considering Purchasing
            Power Parity, the same cappuccino should cost $
            {priceIndonesia} in Indonesia.
          </p>

          <p>
            Transfer this thought process to selling your online
            goods...
          </p>
        </Card>
      </Cards>

      <h2>How to use the PPP API?</h2>

      <Cards>
        <Card>
          <h3>JavaScript API Usage</h3>

          <p>
            The API is available as library for JavaScript
            applications. All the installation instructions can be
            found in the official{' '}
            <a href="https://github.com/rwieruch/purchasing-power-parity">
              PPP API GitHub repository
            </a>
            .
          </p>
        </Card>

        <Card>
          <h3>Direct API Usage</h3>

          <p>
            Use the API endpoint by sending a{' '}
            <a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2">
              ISO country code
            </a>{' '}
            as query parameter. Check out the following{' '}
            <a href="https://api.purchasing-power-parity.com/?target=ID">
              request
            </a>{' '}
            which gets the PPP conversion rate from USD to Indonesian
            IDR.
          </p>
        </Card>

        <Card>
          <h3>Response Object</h3>

          <p>
            Whether you use the PPP API via JavaScript or directly via
            the API endpoint, the response object -- most importantly
            the conversion factor -- will always be based on US
            dollars.
          </p>

          <code>
            <pre>{data ? JSON.stringify(data, null, 2) : null}</pre>
          </code>

          <p>
            Here the conversion factor is ~
            {data ? data.ppp.pppConversionFactor.toFixed(2) : null}.
            So in order to convert your price from USD to Indonesian
            IDR, compute{' '}
            {data ? data.ppp.pppConversionFactor.toFixed(2) : null}{' '}
            times your USD price.
          </p>
        </Card>

        <Card>
          <h3>Contact</h3>

          <p>
            If there is anything wrong with this website or the API,
            please contact{' '}
            <a href="https://twitter.com/rwieruch">@rwieruch</a>.
            Also, if you want to contribute to this project, making
            online products and services more affordable around the
            world, don't hesitate to write a message.
          </p>
        </Card>
      </Cards>
    </div>
  );
};

export default IndexPage;
